//ToplineTable.jsx

import React, { useEffect, useState, useContext } from 'react';
import { Collapse, Table, Button, Input, message, Spin  } from 'antd';
import { useSelector } from 'react-redux';
import api from '../functions/api';
import getUserId from '../functions/getUserId';


const { Panel } = Collapse;

const ToplineTable = ({ updatedCategoryRecords }) => {
  const [showSaxo, setShowSaxo] = useState(false);
  const [showDeGiro, setShowDeGiro] = useState(false);
  // const [showOther, setShowOther] = useState(false);
  const userId = getUserId();
  const demoMode = useSelector(state => state.demoMode.isDemoMode);
  const [latestRefreshEffort, setLatestRefreshEffort] = useState('N/A');

  const [smsCode, setSmsCode] = useState('');  // Define smsCode and setSmsCode
  const [is2FAStep, setIs2FAStep] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // To track loading state
  const [isSmsLoading, setIsSmsLoading] = useState(false); // For SMS submission loading
  const [isUpdateLoading, setIsUpdateLoading] = useState({
    overall: false,
    saxo: false,
    degiro: false,
    other: false
  });
  
  const [isAnyUpdateLoading, setIsAnyUpdateLoading] = useState(false); // New state to track if any update is in progress

  const handleUpdateClick = (column) => {
    console.log('Clicked column:', column);
    setIsAnyUpdateLoading(true); // Disable all update buttons
    setIsUpdateLoading(prevState => ({ ...prevState, [column]: true })); // Show spinner for the clicked column
  
    if (column === 'overall') {
      api.post('/update_overall', { userId })
        .then(response => {
          const { results } = response.data;
          message.success(`Overall update completed: ${results.wando}, ${results.degiro}, ${results.saxo}`);
        })
        .catch(error => {
          message.error('Error updating overall portfolio');
          console.error('Error updating overall portfolio:', error);
        })
        .finally(() => {
          setIsUpdateLoading(prevState => ({ ...prevState, [column]: false }));
          setIsAnyUpdateLoading(false); // Re-enable all buttons after the process
        });
    } else if (column === 'degiro') {
      api.post('/update_dg', { userId })
        .then(response => {
          message.success("DeGiro portfolio updated successfully");
        })
        .catch(error => {
          message.error("Error updating DeGiro portfolio");
          console.error('Error updating DeGiro portfolio:', error);
        })
        .finally(() => {
          setIsUpdateLoading(prevState => ({ ...prevState, [column]: false }));
          setIsAnyUpdateLoading(false); // Re-enable all buttons after the process
        });
    } else if (column === 'saxo') {
      api.post('/update_s', { userId })
        .then(response => {
          message.success("Saxo portfolio updated successfully");
        })
        .catch(error => {
          message.error("Error updating Saxo portfolio");
          console.error('Error updating Saxo portfolio:', error);
        })
        .finally(() => {
          setIsUpdateLoading(prevState => ({ ...prevState, [column]: false }));
          setIsAnyUpdateLoading(false); // Re-enable all buttons after the process
        });
    } else if (column === 'other') {
      api.post('/update_o', { userId })
        .then(response => {
          message.success("W&O portfolio updated successfully");
        })
        .catch(error => {
          message.error("Error updating W&O portfolio");
          console.error('Error updating W&O portfolio:', error);
        })
        .finally(() => {
          setIsUpdateLoading(prevState => ({ ...prevState, [column]: false }));
          setIsAnyUpdateLoading(false); // Re-enable all buttons after the process
        });
    } else {
      message.info(`Update button clicked for ${column}`);
      setIsUpdateLoading(prevState => ({ ...prevState, [column]: false }));
      setIsAnyUpdateLoading(false); // Ensure all buttons are re-enabled
    }
  };

  const handleSmsSubmit = () => {
    setIsSmsLoading(true); // Show spinner while processing SMS code submission
    api.post('/renew_S_token_2', { userId, sms_code: smsCode })
      .then(response => {
        message.success("Saxo login completed successfully");
        setIsSmsLoading(false); // Stop the spinner
        setIs2FAStep(false); // Hide the SMS input
  
        // Re-fetch the token status to update the latestRefreshEffort
        api.post('/token_status', { userId })
          .then(response => {
            if (response.data && response.data.result_latest_refresh_effort) {
              setLatestRefreshEffort(response.data.result_latest_refresh_effort);
            } else {
              setLatestRefreshEffort('N/A');
            }
          })
          .catch(error => {
            message.error('Error fetching updated token status');
            console.error('Error fetching token status after SMS submit:', error);
          });
  
      })
      .catch(error => {
        message.error('Error completing token refresh');
        setIsSmsLoading(false); // Stop the spinner if error
        setIs2FAStep(true); // Keep showing the SMS input for retry
        console.error('Error completing token refresh:', error);
      });
  };


  // Handle the refresh token request
  const handleRefreshToken = () => {
    setIsLoading(true); // Show spinner for refresh token process
    api.post('/renew_S_token_1', { userId })
      .then(response => {
        if (response.data.message === "Saxo login initiated, waiting for 2FA code") {
          message.success("Saxo login initiated, waiting for 2FA code");
          setIs2FAStep(true); // Show the SMS input and button
        }
        setIsLoading(false); // Stop the spinner
      })
      .catch(error => {
        message.error('Error initiating token refresh');
        setIsLoading(false); // Stop the spinner in case of error
        console.error('Error initiating token refresh:', error);
      });
  };

  
  
 
  useEffect(() => {
    const userId = getUserId();
  
    // Call the new API to get the result_latest_refresh_effort
    api.post('/token_status', { userId })
      .then(response => {
        if (response.data && response.data.result_latest_refresh_effort) {
          setLatestRefreshEffort(response.data.result_latest_refresh_effort);
  
          // Log the value directly from the response
          console.log("Latest effort ", response.data.result_latest_refresh_effort);
        } else {
          setLatestRefreshEffort('N/A');
        }
      })
      .catch(error => {
        console.error('Error fetching token status:', error);
        setLatestRefreshEffort('N/A');
      });
  
    // Existing API call to check for authorization
    api.post('/Broker_checkifauthorisationavailable', { userId })
      .then(response => {
        setShowSaxo(response.data.saxo);
        setShowDeGiro(response.data.degiro);
      })
      .catch(error => {
        console.error('Error checking authorization:', error);
      });
  }, []);
  
  // Optional: You can observe the updated value using useEffect
  useEffect(() => {
    console.log("Latest effort (from state):", latestRefreshEffort);
  }, [latestRefreshEffort]);
  
  

  const overallTopline = useSelector(state => state.portfolio && state.portfolio.data ? state.portfolio.data.portfolio_topline : null);
  const saxoTopline = useSelector(state => state.portfolio && state.portfolio.data ? state.portfolio.data.saxo_topline : null);
  const dgTopline = useSelector(state => state.portfolio && state.portfolio.data ? state.portfolio.data.dg_portfolio_topline : null);
  const other =useSelector(state => state.portfolio && state.portfolio.data ? state.portfolio.data.Topline_Other: null);

  // Determine if the 'Other' section should be shown
  const showOther = other && other.length > 0;
  const otherName = showOther ? other[0].name : 'Other';

  const overallToplineData = (overallTopline && overallTopline[0]) || {};
  // console.log("overalltoplinedata:",overallToplineData);
  const saxoToplineData = (saxoTopline && saxoTopline[0]) || {};
  // console.log("saxotoplinedata:",saxoToplineData);
  const dgToplineData = (dgTopline && dgTopline[0]) || {};
  // console.log("dgtoplinedata:",dgToplineData);
  const otherData = (other && other[0]) || {};

  const renderIndicatorLabel = (text) => {
    if (text === 'Portfolio value') {
        return (
            <div>
                {text}
                <div style={{ fontSize: 'smaller', marginTop: '4px', fontStyle: 'italic' }}>cash</div>
            </div>
        );
    } else if (text === 'Overall return') {
        return (
            <div>
                {text}
                <div style={{ fontSize: 'smaller', marginTop: '4px', fontStyle: 'italic' }}>performance</div>
            </div>
        );
    }
    return text;
};

const renderCashAvailable = (text, record, column) => {
  let secondaryValue = '';
  let secondaryStyle = { fontSize: 'smaller', marginTop: '4px', fontStyle: 'italic' };

  switch (column) {
    case 'overall':
      if (record.indicator === 'Portfolio value') secondaryValue = record.overallCash;
      if (record.indicator === 'Overall return') secondaryValue = record.overallPerformance;
      break;
    case 'saxo':
      if (record.indicator === 'Portfolio value') secondaryValue = record.saxoCash;
      if (record.indicator === 'Overall return') secondaryValue = record.saxoPerformance;
      break;
    case 'degiro':
      if (record.indicator === 'Portfolio value') secondaryValue = record.degiroCash;
      if (record.indicator === 'Overall return') secondaryValue = record.degiroPerformance;
      break;
    case 'other':
      if (record.indicator === 'Portfolio value') secondaryValue = record.otherCash;
      if (record.indicator === 'Overall return') secondaryValue = record.otherPerformance;
      break;
  }

  // For the "Latest update" row, return only the primary value (i.e., "X hours ago") and no secondary value
  if (record.indicator === 'Latest update') {
    return (
      <div>
        {text} {/* This will display "X hours ago" */}
        <Button 
          type="primary" 
          onClick={() => handleUpdateClick(column)} 
          style={{ marginTop: '8px' }}
          size="small"
          disabled={isAnyUpdateLoading || (column === 'saxo' && latestRefreshEffort !== '200' && latestRefreshEffort !== '201')} // Disable all buttons if any update is in progress
        >
          {isUpdateLoading[column] ? <Spin /> : 'Update'}
        </Button>
      </div>
    );
  }

  return (
    <div>
      {text}
      <div style={secondaryStyle}>{secondaryValue}</div>
    </div>
  );
};




function calculateHoursAgo(epoch) {
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  const timeDifference = currentTime - epoch; // Difference in seconds
  const hoursAgo = Math.floor(timeDifference / 3600); // Convert to hours
  return `${hoursAgo} hours ago`;
}


  const dataSource = [
    {
      key: '1',
      indicator: 'Portfolio value',
      overall: overallToplineData.portfolio_total_value ? `€ ${overallToplineData.portfolio_total_value}` : '€ 0',
      overallCash: overallToplineData.cash_eur ? `€ ${overallToplineData.cash_eur}` : '€ 0',
      saxo: saxoToplineData.Saxo_portfolio_total_value ? `€ ${saxoToplineData.Saxo_portfolio_total_value}` : '€ 0',
      saxoCash: saxoToplineData.Saxo_cash_eur ? `€ ${saxoToplineData.Saxo_cash_eur}` : '€ 0',
      degiro: dgToplineData.portfolio_total_value ? `€ ${dgToplineData.portfolio_total_value}` : '€ 0',
      degiroCash: dgToplineData.cash_eur_value ? `€ ${dgToplineData.cash_eur_value}` : '€ 0',
      other: otherData.value ? `€ ${otherData.value}` : '€ 0',
      // otherCash: otherData.cashinenout ? `€ ${otherData.cashinenout}` : '€ 0'
    },
    
    {
      key: '2',
      indicator: 'Overall return',
      overall: overallToplineData.portfolio_result ? `€ ${overallToplineData.portfolio_result}` : '€ 0',
      overallPerformance: overallToplineData.portfolio_performance ? `${overallToplineData.portfolio_performance}%` : 'N/A',
      saxo: saxoToplineData.Saxo_portfolio_result ? `€ ${saxoToplineData.Saxo_portfolio_result}` : '€ 0',
      saxoPerformance: saxoToplineData.Saxo_portfolio_performance ? `${saxoToplineData.Saxo_portfolio_performance}%` : 'N/A',
      degiro: dgToplineData.portfolio_result ? `€ ${dgToplineData.portfolio_result}` : '€ 0',
      degiroPerformance: dgToplineData.portfolio_performance ? `${dgToplineData.portfolio_performance}%` : 'N/A',
      other: otherData.result_value ? `€ ${otherData.result_value}` : '€ 0',
      otherPerformance: otherData.performance ? ` ${otherData.performance}%` : 'N/A',
      
    },
    {
      key: '3',
      indicator: 'Latest update',
      overall: overallToplineData.epoch ? calculateHoursAgo(overallToplineData.epoch) : 'N/A', // Display hours ago for Overall
      // overall: overallToplineData.portfolio_result ? `€ ${overallToplineData.portfolio_result}` : '€ 0',
      // overallPerformance: overallToplineData.portfolio_performance ? `${overallToplineData.portfolio_performance}%` : 'N/A',
      saxo: saxoToplineData.epoch ? calculateHoursAgo(saxoToplineData.epoch) : 'N/A', // Display hours ago for Saxo
      degiro: dgToplineData.epoch ? calculateHoursAgo(dgToplineData.epoch) : 'N/A', // Display hours ago for DeGiro
      other: otherData.epoch ? calculateHoursAgo(otherData.epoch) : 'N/A', // Display hours ago for Other data
    }
    
  ];
  
  const baseColumns = [
   {
        title: '',
        dataIndex: 'indicator',
        key: 'indicator',
        render: renderIndicatorLabel // Use this custom render function for the indicator column
    },
    {
        title: 'Overall',
        dataIndex: 'overall',
        key: 'overall',
        render: (text, record) => renderCashAvailable(text, record, 'overall')
    }
  ];
  
  if (showSaxo) {
    baseColumns.push({
      title: 'Saxo',
      dataIndex: 'saxo',
      render: (text, record) => renderCashAvailable(text, record, 'saxo')
    });
  }
  
  if (showDeGiro) {
    baseColumns.push({
      title: 'DeGiro',
      dataIndex: 'degiro',
      key: 'degiro',
      render: (text, record) => renderCashAvailable(text, record, 'degiro')
    });
  }
  
  if (showOther) {
    baseColumns.push({
      title: otherName,
      // title: 'Other',
      dataIndex: 'other',
      key: 'other',
      render: (text, record) => renderCashAvailable(text, record, 'other') // Ensure this calls the renderCashAvailable function
      // render: renderCashAvailable // No need to adjust for 'other' as it's 'N/A'
    });
  }


  
  const portfolioData = overallTopline ? overallTopline[0] : {};

 // Conditional styling based on demo mode
 const headerStyle = demoMode ? "blur-text" : "";
 const resultText = portfolioData.portfolio_result ? `€${portfolioData.portfolio_result}` : 'N/A';
 const performanceText = portfolioData.portfolio_performance ? `${portfolioData.portfolio_performance}%` : 'N/A';

 const headerContent = (
   <div>
     YTD Result: <span className={headerStyle}>{resultText}</span>, YTD Performance: {performanceText}
   </div>
 );


 return (
  <Collapse defaultActiveKey={['1']}>
    <Panel header="Your Portfolio" key="1">
      <Table dataSource={dataSource} columns={baseColumns} pagination={false} />

      {/* Only show this section if the latest refresh effort was unsuccessful */}
      {showSaxo && latestRefreshEffort !== '200' && latestRefreshEffort !== '201' && (
        <div style={{ marginTop: '20px' }}>
          <h3>Refresh Token Process</h3>

          {/* Show the Refresh token button with a spinner when clicked */}
          <Button
            type="primary"
            onClick={handleRefreshToken}
            style={{ marginBottom: '10px' }}
            disabled={is2FAStep || isLoading} // Disable when in 2FA step or loading
          >
            {isLoading ? <Spin /> : 'Refresh token'}
          </Button>

          {/* Show SMS input and submit button only after 2FA step starts */}
          {is2FAStep && (
            <div style={{ marginTop: '20px' }}>
              <Input
                type="text"
                value={smsCode}
                onChange={(e) => setSmsCode(e.target.value)}
                placeholder="Enter the 2FA code"
                style={{ width: '300px', marginRight: '10px' }}
              />
              <Button
                type="primary"
                onClick={handleSmsSubmit}
                disabled={isSmsLoading} // Disable the submit button while loading
              >
                {isSmsLoading ? <Spin /> : 'Submit'}
              </Button>
            </div>
          )}
        </div>
      )}
    </Panel>
  </Collapse>
);

};

export default ToplineTable;
