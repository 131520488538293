import React, { useState } from "react";
import { AutoComplete, Input } from "antd";

const FieldSearch = ({ dashboardStructure, onFieldSelect }) => {
  const [options, setOptions] = useState([]); // State to store filtered options

  // Function to handle search input changes
  const handleSearch = (value) => {
    if (!value) {
      setOptions([]); // Clear options if no input
      return;
    }

    const newOptions = []; // Array to store matching options

    // Iterate over the dashboardStructure to match field names
    Object.keys(dashboardStructure).forEach((tableName) => {
      const columns = dashboardStructure[tableName];
      columns.forEach((column) => {
        if (column.name.toLowerCase().includes(value.toLowerCase())) {
          newOptions.push({
            value: column.name, // Store only the field name in the AutoComplete
            label: `${column.name} - Source: ${tableName}`, // Display field and source table for users
            field: column.name, // Store field name separately
            table: tableName,   // Store the source table separately
          });
        }
      });
    });

    setOptions(newOptions); // Update options with filtered results
  };

  // Function to handle when an option is selected
  const handleSelect = (value, option) => {
    // Call the onFieldSelect prop and pass back the field and source table
    onFieldSelect({ field: option.field, table: option.table });
  };

  return (
    <AutoComplete
      style={{ width: 300 }}
      onSearch={handleSearch}
      onSelect={handleSelect} // Call handleSelect when an option is chosen
      options={options} // Render the filtered options
      placeholder="Search for a field"
    >
      <Input.Search size="large" enterButton />
    </AutoComplete>
  );
};

export default FieldSearch;
