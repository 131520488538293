import { message } from 'antd';
import api from './api';  // Reuse your existing Axios instance

// Function to fetch all clauses (GET)
export const fetchClauses = async () => {
  try {
    const response = await api.get('/alert_clause_definition');
    message.success('Clauses fetched successfully!');
    return response.data;
  } catch (error) {
    message.error('Error fetching clauses.');
    console.error('Error fetching clauses:', error);
    throw error;
  }
};

// Function to add a new clause (POST)
export const addClause = async (clauseData) => {
  try {
    const response = await api.post('/alert_clause_definition', clauseData);
    message.success('Clause added successfully!');
    return response.data;
  } catch (error) {
    message.error('Error adding clause.');
    console.error('Error adding clause:', error);
    throw error;
  }
};

// Function to update an existing clause (PUT)
export const updateClause = async (id, clauseData) => {
  try {
    const response = await api.put(`/alert_clause_definition/${id}`, clauseData);
    message.success('Clause updated successfully!');
    return response.data;
  } catch (error) {
    message.error('Error updating clause.');
    console.error(`Error updating clause with ID ${id}:`, error);
    throw error;
  }
};

// Function to delete a clause (DELETE)
export const deleteClause = async (id) => {
  try {
    const response = await api.delete(`/alert_clause_definition/${id}`);
    message.success('Clause deleted successfully!');
    return response.data;
  } catch (error) {
    message.error('Error deleting clause.');
    console.error(`Error deleting clause with ID ${id}:`, error);
    throw error;
  }
};

// Function to fetch distinct values for a specific field from a specific table
export const fetchTargetFieldValues = async (table, field) => {
  try {
    const response = await api.get(`/field_values`, {
      params: {
        table: table,
        field: field
      }
    });
    message.success('Field values fetched successfully!');
    console.log("Field values ", response)
    return response.data;
  } catch (error) {
    message.error('Error fetching target field values.');
    console.error('Error fetching target field values:', error);
    throw error;
  }
};

// Function to test the clause (POST)
export const testClause = async (clauseData) => {
  try {
    const response = await api.post('/test_clause', clauseData);
    message.success('Clause tested successfully!');
    return response.data;
  } catch (error) {
    message.error('Error testing clause.');
    console.error('Error testing clause:', error);
    throw error;
  }
};

// Function to test the Alert (POST)
export const testAlert = async (Alert_ID) => {
  try {
    const response = await api.post('/test_alert', Alert_ID);
    message.success('Alert tested successfully!');
    return response.data;
  } catch (error) {
    message.error('Error testing alert.');
    console.error('Error testing alert:', error);
    throw error;
  }
};


