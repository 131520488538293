import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Button, Alert } from 'antd';
import { fetchTargetFieldValues, updateClause } from '../functions/alertClauseService';
import TextCrafter from './TextCrafter';

const { Option } = Select;

const ClauseEditor = ({ visible, onClose, clause, dashboardStructure, refreshClauses }) => {
  const [editingClause, setEditingClause] = useState(clause);
  const [targetFieldValues, setTargetFieldValues] = useState([]);
  const [thresholdTargetFieldValues, setThresholdTargetFieldValues] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);  // Tracks if there are changes
  const operatorOptions = ['>', '<', '=', '>=', '<=', '!='];
  const [showWarning, setShowWarning] = useState(false);
  const [isTextCrafterVisible, setTextCrafterVisible] = useState(false);


  console.log("clause in ClauseEditor", clause)

  useEffect(() => {
    setEditingClause(clause);
    setHasChanges(false); // Reset changes when a new clause is loaded
  }, [clause]);

  useEffect(() => {
    if (editingClause?.clause_field_target && editingClause?.clause_field_sourcetable) {
      const loadTargetFieldValues = async () => {
        try {
          const values = await fetchTargetFieldValues(editingClause.clause_field_sourcetable, editingClause.clause_field_target);
          setTargetFieldValues(values);
        } catch (error) {
          console.error('Error fetching target field values:', error);
        }
      };
      loadTargetFieldValues();
    }
  }, [editingClause?.clause_field_target, editingClause?.clause_field_sourcetable]);

  useEffect(() => {
    if (editingClause?.threshold_field_target && editingClause?.threshold_sourcetable) {
      const loadThresholdTargetFieldValues = async () => {
        try {
          const values = await fetchTargetFieldValues(editingClause.threshold_sourcetable, editingClause.threshold_field_target);
          setThresholdTargetFieldValues(values);
        } catch (error) {
          console.error('Error fetching threshold target field values:', error);
        }
      };
      loadThresholdTargetFieldValues();
    }
  }, [editingClause?.threshold_field_target, editingClause?.threshold_sourcetable]);

  // Update handleFieldChange to trigger the warning condition
const handleFieldChange = (field, value) => {
    setEditingClause(prev => ({ ...prev, [field]: value }));
    setHasChanges(true);  // Mark as changed
  
    // Check for threshold value and other threshold-related fields
    if (field === 'threshold' && value) {
      const { threshold_field, threshold_field_target, threshold_field_target_value } = editingClause;
      if (threshold_field || threshold_field_target || threshold_field_target_value) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    } else if (field === 'threshold_field' || field === 'threshold_field_target' || field === 'threshold_field_target_value') {
      if (editingClause.threshold) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    } else {
      setShowWarning(false);
    }
  };

  const handleSaveClause = async () => {
    try {
      await updateClause(editingClause.ID, editingClause);
      onClose(); // Close modal after saving
      refreshClauses(); // Reload the clauses to show updated data
    } catch (error) {
      console.error('Error saving clause:', error);
    }
  };

  const getFieldOptions = (table, excludeField = null) => {
    const options = [];
    if (dashboardStructure && table) {
      dashboardStructure[table]?.forEach(field => {
        if (field.name !== excludeField) {
          options.push({
            value: field.name,
            label: field.name,
          });
        }
      });
    }
    return options;
  };

  // Form validation: Ensure required fields are not empty
  const isFormValid = () => {
    const clauseRelatedFieldsPopulated = editingClause?.clause_field && editingClause?.clause_operator;
    const thresholdFieldPopulated = editingClause?.threshold_field;
    
    // Condition 1: Clause-related fields, operator, and threshold field populated
    const condition1 = clauseRelatedFieldsPopulated && thresholdFieldPopulated;
  
    // Condition 2: Clause-related fields, operator, and all threshold-related fields populated
    const condition2 = clauseRelatedFieldsPopulated &&
      editingClause?.threshold_field &&
      editingClause?.threshold_sourcetable &&
      editingClause?.threshold_field_target &&
      editingClause?.threshold_field_target_value;
  
    return condition1 || condition2;
  };

  return (
    <>
    <Modal
      title="Edit Clause"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
        key="save"
        type="primary"
        onClick={handleSaveClause}
        disabled={!hasChanges || !isFormValid()} // Disable if no changes or form is invalid
      >
        Save
      </Button>
      ]}
      width={600}
    >
      <Form layout="vertical">
        <Form.Item label="Clause Field" required>
          <Select
            showSearch
            value={editingClause.clause_field}
            onChange={(value, option) => {
              handleFieldChange('clause_field', value);
              handleFieldChange('clause_field_sourcetable', option.table);
            }}
          >
            {Object.keys(dashboardStructure).map(table =>
              dashboardStructure[table].map(field => (
                <Option key={field.name} value={field.name} table={table}>
                  {`${field.name} (${table})`}
                </Option>
              ))
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Clause Field Sourcetable">
          <Input value={editingClause.clause_field_sourcetable} disabled />
        </Form.Item>

        <Form.Item label="Clause Field Target">
          <Select
            showSearch
            value={editingClause.clause_field_target}
            onChange={value => handleFieldChange('clause_field_target', value)}
          >
            {getFieldOptions(editingClause.clause_field_sourcetable, editingClause.clause_field).map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Clause Field Target Value">
          <Select
            showSearch
            value={editingClause.clause_field_target_value}
            onChange={value => handleFieldChange('clause_field_target_value', value)}
          >
            {targetFieldValues.map(value => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Operator" required>
          <Select
            value={editingClause.clause_operator}
            onChange={value => handleFieldChange('clause_operator', value)}
          >
            {operatorOptions.map(op => (
              <Option key={op} value={op}>
                {op}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Threshold">
          <Input value={editingClause.threshold} onChange={e => handleFieldChange('threshold', e.target.value)} />
        </Form.Item>

        <Form.Item label="Threshold Field" required>
          <Select
            showSearch
            value={editingClause.threshold_field}
            onChange={(value, option) => {
              handleFieldChange('threshold_field', value);
              handleFieldChange('threshold_sourcetable', option.table);
            }}
          >
            {Object.keys(dashboardStructure).map(table =>
              dashboardStructure[table].map(field => (
                <Option key={field.name} value={field.name} table={table}>
                  {`${field.name} (${table})`}
                </Option>
              ))
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Threshold Sourcetable">
          <Input value={editingClause.threshold_sourcetable} disabled />
        </Form.Item>

        <Form.Item label="Threshold Field Target">
          <Select
            showSearch
            value={editingClause.threshold_field_target}
            onChange={value => handleFieldChange('threshold_field_target', value)}
          >
            {getFieldOptions(editingClause.threshold_sourcetable, editingClause.threshold_field).map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Threshold Field Target Value">
          <Select
            showSearch
            value={editingClause.threshold_field_target_value}
            onChange={value => handleFieldChange('threshold_field_target_value', value)}
          >
            {thresholdTargetFieldValues.map(value => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Clause Text">
  <Button
    onClick={() => {
      console.log("Opening TextCrafter modal...");
      setTextCrafterVisible(true);
    }}
  >
    Edit Clause Text: {editingClause?.clause_txt}
  </Button>
</Form.Item>





      </Form>

      {showWarning && (
          <Alert message="If Threshold has a value, the clause will use that as the threshold value!" type="warning" showIcon />
        )}

    </Modal>
    
    {/* Put the TextCrafter modal here */}
    {isTextCrafterVisible && (
        <TextCrafter
          dashboardStructure={dashboardStructure}
          initialText={editingClause?.clause_txt} // Pass clause text as initialText
          onSave={(updatedText) => {
            console.log("Updated Text:", updatedText);
            // Update the editingClause with the new text
            setEditingClause(prev => ({ ...prev, clause_txt: updatedText }));
            setHasChanges(true); // Mark that changes have been made
            setTextCrafterVisible(false); // Close modal
          }}
          onClose={() => {
            console.log("Closing TextCrafter modal...");
            setTextCrafterVisible(false);
          }}
        />
      )}



      </>
        
      );
  
};

export default ClauseEditor;
