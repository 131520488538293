import React, { useState, useEffect } from "react";
import { Button, Input, Form, Divider, message, Table, Spin, Alert, Collapse, Modal, Popconfirm } from "antd";
import ClauseCrafter from "./ClauseCrafter";
import { fetchClauses, addClause, deleteClause, fetchTargetFieldValues, testClause, testAlert } from "../functions/alertClauseService";

const { Panel } = Collapse;

const AlertCrafter = ({
    onAlertSubmit,
    onAlertEdit,
    onAlertDelete,  // Pass delete function
    alerts,
    alertsLoading,
    alertsError,
    alertColumns,
    tableTitle,
  }) => {
    const [form] = Form.useForm();
    const [alertClauses, setAlertClauses] = useState([]);
    const [isClauseSelected, setIsClauseSelected] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedAlert, setEditedAlert] = useState(null);  
    const [isCreatingNewAlert, setIsCreatingNewAlert] = useState(false); 
    const [allClauses, setAllClauses] = useState([]);
    const [clauseTestResults, setClauseTestResults] = useState({});

    const [initialFormValues, setInitialFormValues] = useState(null);  // NEW
    const [initialClauses, setInitialClauses] = useState([]);         // NEW
    const [alertTestResult, setAlertTestResult] = useState(null);
    const [alertTestAdvice, setAlertTestAdvice] = useState(null);

    const handleTestAlert = async (alertId) => {
        if (!alertId) {
            message.error('No alert to test');
            return;
        }
        try {
            const result = await testAlert({ Alert_ID: alertId });  // Assuming testAlert expects an object with `Alert_ID`
            setAlertTestResult(result.message);
            setAlertTestAdvice(result.generic_advice);  // Assuming the result has a `message` field
            console.log("alertTestAdvice", alertTestAdvice)
            message.success('Alert tested successfully!');
        } catch (error) {
            message.error('Failed to test alert');
        }
    };

    const handleTestClause = async (clause) => {
        try {
          const result = await testClause(clause); // Call the testClause function
          console.log("In Alertcrafter clause-test_results", result)
          // Store the result in clauseTestResults state, using clause.ID as key
          setClauseTestResults((prevResults) => ({
            ...prevResults,
            [clause.ID]: result.message, // Assuming the test result has a 'message' field
          }));
          message.success('Clause tested successfully!');
        } catch (error) {
          message.error('Failed to test clause');
        }
      };
      

    // In AlertCrafter.jsx
    // const handleAddExistingClauseToAlert = (clause) => {
    //     setAlertClauses((prevClauses) => {
    //       const updatedClauses = [...prevClauses, clause];
      
    //       if (isEditing && editedAlert) {
    //         const updatedAlertData = {
    //           ...editedAlert,
    //           clauses: updatedClauses.map((c) => c.ID).join(","), // Update clause IDs
    //         };
            
    //         // Call the function to update the alert in the backend
    //         onAlertEdit(updatedAlertData);
      
    //         message.success("Clause added and alert updated!");
    //       }
      
    //       return updatedClauses;
    //     });
    //   };
      
  
  
    const handleClausesAvailable = (clauses) => {
        // console.log("Received Clauses from ClauseCrafter:", clauses); // Log received clauses
        setAllClauses(clauses);  // Store the full list of clauses
      };
      
      
      
    const handleEditAlert = (alertId) => {
        const alertToEdit = alerts.find((alert) => alert.ID === alertId);
        if (alertToEdit) {
          setIsEditing(true);
          setEditedAlert(alertToEdit); 
        }
      };
    
      const handleDeleteAlert = (alertId) => {
        Modal.confirm({
          title: "Are you sure you want to delete this alert?",
          onOk: () => {
            onAlertDelete(alertId);  // Call the delete function passed from the parent
          }
        });
      };
    
      const alertColumnsWithActions = [
        ...alertColumns,
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <>
              <Button type="link" onClick={() => handleEditAlert(record.ID)}>
                Edit
              </Button>
              <Button type="link" danger onClick={() => handleDeleteAlert(record.ID)}>
                Delete
              </Button>
            </>
          ),
        }
      ];

      useEffect(() => {
        if (editedAlert && allClauses.length) {
        // if (editedAlert) {
        // if (isEditing && editedAlert) {
          form.setFieldsValue({
            alert_type: editedAlert.alert_type,
            alert_subtype: editedAlert.alert_subtype,
            advice_txt: editedAlert.advice_txt,
          });
      
          // Log the clause IDs in the alert
        //   console.log("Clause IDs in Edited Alert:", editedAlert.clauses);
      
          const alertClauseIds = editedAlert.clauses.split(",").map(id => id.trim());
          const associatedClauses = allClauses.filter(clause => alertClauseIds.includes(clause.ID.toString()));
          
          // Log to verify the filtering
        //   console.log("Filtered Clauses:", associatedClauses);
      
          setAlertClauses(associatedClauses);  // Store only the clauses relevant to this alert
          setIsClauseSelected(true);
          setInitialClauses(associatedClauses);
          setInitialFormValues(form.getFieldsValue()); 
        }
    }, [editedAlert]);
    // }, [editedAlert, allClauses]);  // Only run when editedAlert or allClauses changes
    //   }, [isEditing, editedAlert, allClauses, form]);
      
      
  

    const handleFormChange = () => {
        const fields = form.getFieldsValue();
        const isValid = fields.alert_type && fields.alert_subtype && fields.advice_txt;
    
        // Check if form or clauses have changed compared to the initial values
        const formChanged = JSON.stringify(fields) !== JSON.stringify(initialFormValues);
        const clausesChanged = JSON.stringify(alertClauses) !== JSON.stringify(initialClauses);
    
        setIsFormValid(isValid && (formChanged || clausesChanged));  // Enable button only if something changed
    };
    

// When a clause is added
const handleClauseSubmit = (clause) => {
    setAlertClauses((prevClauses) => [...prevClauses, clause]);  // No immediate handleFormChange here
    setIsClauseSelected(true);
    message.success("Clause added to alert!");
};

// When a clause is removed
const handleClauseRemove = (removedClauseId) => {
    const updatedClauses = alertClauses.filter((clause) => clause.ID !== removedClauseId);
    setAlertClauses(updatedClauses);  // No immediate handleFormChange here
    message.success("Clause removed from alert!");

    // If editing, update the alert in the backend
    if (isEditing && editedAlert) {
        const updatedAlertData = {
            ...editedAlert,
            clauses: updatedClauses.map((clause) => clause.ID).join(","),
        };
        onAlertEdit(updatedAlertData);
    }
};

useEffect(() => {
    // Trigger form validation after clause changes
    handleFormChange();
}, [alertClauses]);  // Re-run handleFormChange whenever alertClauses changes

  
  
  const handleFinish = (values) => {
    const alertData = {
        ...values,
        clauses: alertClauses.map((clause) => clause.ID).join(","),
    };

    if (isEditing) {
        onAlertEdit({ ...alertData, id: editedAlert.ID });
        message.success('Alert updated successfully!');

        // Reset the initial values to the current state after successful update
        setInitialFormValues(values);  // Track the current form values
        setInitialClauses(alertClauses);  // Track the current clause values
        setIsFormValid(false);  // Disable the button after updating
    } else {
        onAlertSubmit(alertData);
        setIsCreatingNewAlert(false);
    }
};



  // Track and log state changes
  useEffect(() => {
    // console.log("use effect : isEditing after state change", isEditing);
    // console.log("use effect : isCreatingNewAlert after state change", isCreatingNewAlert);
  }, [isEditing, isCreatingNewAlert]);


  return (
    <div>
      <h3>{isEditing ? "Edit Alert" : "Manage Alerts"}</h3>

      {/* "Create New Alert" button */}
      {!isCreatingNewAlert && !isEditing && (
        
        <Button type="primary" onClick={() => setIsCreatingNewAlert(true)}>
          Create New Alert
        </Button>
      )}

      {/* Form to create a new alert or edit an existing one */}
      {(isCreatingNewAlert || isEditing) && (
        <Form
          form={form}
          onFinish={handleFinish}
          layout="vertical"
          onValuesChange={handleFormChange}
        >
          <Form.Item
            label="Alert Type"
            name="alert_type"
            rules={[{ required: true, message: "Alert type is required" }]}
          >
            <Input placeholder="Enter alert type" />
          </Form.Item>

          <Form.Item
            label="Alert Subtype"
            name="alert_subtype"
            rules={[{ required: true, message: "Alert subtype is required" }]}
          >
            <Input placeholder="Enter alert subtype" />
          </Form.Item>

          <Form.Item
            label="Advice Text"
            name="advice_txt"
            rules={[{ required: true, message: "Advice text is required" }]}
          >
            <Input.TextArea rows={3} placeholder="Enter advice text" />
          </Form.Item>

          <Button type="primary" htmlType="submit" disabled={!isFormValid || !isClauseSelected}>  
                {isEditing ? "Update Alert" : "Create Alert"}
            </Button>


          {/* Cancel button */}
          <Button
  type="default"
  onClick={() => {
    // Reset form fields
    form.resetFields();

    // Set both isCreatingNewAlert and isEditing to false
    setIsCreatingNewAlert(false);  // Reset creation state
    setIsEditing(false);           // Reset editing state
    setEditedAlert(null);          // Clear the edited alert
    setAlertClauses([]);           // Clear selected clauses

    console.log("setIsEditing after cancel", setIsEditing);
    console.log("isEditing after cancel", isEditing);
    console.log("setIsCreatingNewAlert after cancel", setIsCreatingNewAlert);
    console.log("isCreatingNewAlert after cancel", isCreatingNewAlert);
  }}
  style={{ marginLeft: 8 }}
>
  Cancel
</Button>

{/* Test Alert Button */}
<Button 
  type="default" 
  onClick={() => handleTestAlert(editedAlert?.ID)}  // Trigger test alert with the ID
  style={{ marginLeft: 8 }}
>
  Test Alert
</Button>

{alertTestResult && (
  <div style={{ marginTop: '16px' }}>
    <b>Test Alert Result:</b> {alertTestResult}
    <br />
    <b>Alert Text:</b> {alertTestAdvice}
  </div>
)}


          <Divider />

          <h4>Clauses for this alert:</h4>
          <Table
                key={alertClauses.length}  // Force re-render on state change
                columns={[
                    { title: "Clause Field", dataIndex: "clause_field", key: "clause_field" },
                    { title: "Clause Text", dataIndex: "clause_txt", key: "clause_txt" },
                    { title: "Operator", dataIndex: "clause_operator", key: "clause_operator" },
                    { title: "Threshold", dataIndex: "threshold", key: "threshold" },
                    { title: "Threshold Field", dataIndex: "threshold_field", key: "threshold_field" },
                    {
                    title: "Action",
                    key: "action",
                    render: (text, record) => (
                        <>
                        <Popconfirm
                            title="Are you sure you want to remove this clause?"
                            onConfirm={() => handleClauseRemove(record.ID)}  // Call remove function on confirmation
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="link" danger>
                            Remove
                            </Button>
                        </Popconfirm>

                        {/* Add Test Button */}
                        <Button type="default" onClick={() => handleTestClause(record)}>
                            Test
                        </Button>

                        {/* Conditionally render the test result if it exists */}
                        {clauseTestResults[record.ID] && (
                            <div style={{ marginTop: '8px' }}>
                            <b>Test Result:</b> {clauseTestResults[record.ID]}
                            </div>
                        )}
                        </>
                    ),
                    },
                ]}
                dataSource={alertClauses}  // Ensure this points to the alertClauses state
                pagination={false}
                rowKey="ID"
                />


          <Divider />

          <ClauseCrafter
                onClauseSubmit={handleClauseSubmit}
                onClauseRemove={handleClauseRemove}
                isCreating={isCreatingNewAlert}
                isEditing={isEditing}
                onClausesAvailable={handleClausesAvailable}
                alertClauses={alertClauses}   // Pass the alertClauses state
                setAlertClauses={setAlertClauses}  // Pass the setAlertClauses function
                />

        </Form>
      )}

      {/* Collapse for showing alerts in a table */}
      <Collapse defaultActiveKey={["1"]}>
        <Panel header={tableTitle} key="1">
          {alertsLoading ? (
            <Spin tip="Loading alerts..." />
          ) : alertsError ? (
            <Alert message={alertsError} type="error" />
          ) : (
            <Table
      columns={alertColumnsWithActions}
      dataSource={alerts}
      pagination={false}
      bordered
    />
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default AlertCrafter;

