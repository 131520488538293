import React, { useState, useEffect } from "react";
import { Table, Spin, Alert, Modal, Collapse, message, Button } from "antd";
import AlertCrafter from "./AlertCrafter";
import api from '../functions/api';

const MarketAlerts = ({ isVisible, onClose }) => {
  const [alerts, setAlerts] = useState([]);
  const [alertsLoading, setAlertsLoading] = useState(true);
  const [alertsError, setAlertsError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);  
  const [editedAlert, setEditedAlert] = useState(null);  

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await api.get('/market_alert');
        const data = response.data;
        setAlerts(data);
        setAlertsLoading(false);
      } catch (error) {
        console.error("Error fetching alerts:", error);
        setAlertsError("Error fetching alerts");
        setAlertsLoading(false);
      }
    };

    fetchAlerts();
  }, []);

  const handleDeleteAlert = (alertId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this alert?",
      onOk: () => {
        api.delete(`/market_alert`, { data: { id: alertId } })
          .then(() => {
            setAlerts((prevAlerts) => prevAlerts.filter(alert => alert.ID !== alertId));
            message.success('Alert deleted successfully');
          })
          .catch((error) => {
            console.error('Error deleting alert:', error);
            message.error('Failed to delete alert');
          });
      }
    });
  };

  const handleUpdateAlert = (updatedAlertData) => {
    api.put('/market_alert', updatedAlertData)
      .then(() => {
        setAlerts((prevAlerts) =>
          prevAlerts.map((alert) =>
            alert.ID === updatedAlertData.id ? { ...alert, ...updatedAlertData } : alert
          )
        );
        message.success('Alert updated successfully');
      })
      .catch((error) => {
        console.error('Error updating alert:', error);
        message.error('Failed to update alert');
      });
  };

  const handleCreateNewAlert = () => {
    setIsCreating(true);
    setEditedAlert(null); 
  };

  const alertColumns = [
    { title: "ID", dataIndex: "ID", key: "ID" },
    { title: "Alert Type", dataIndex: "alert_type", key: "alert_type" },
    { title: "Advice Text", dataIndex: "advice_txt", key: "advice_txt" },
    { title: "Clauses", dataIndex: "clauses", key: "clauses" },
    { title: "Subtype", dataIndex: "alert_subtype", key: "alert_subtype" },
    { title: "Status", dataIndex: "status", key: "status" }
  ];

  return (
    <Modal
      title="Creating Market Alerts"
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      width={2500}
    >
     

      <AlertCrafter
        alerts={alerts}
        alertsLoading={alertsLoading}
        alertsError={alertsError}
        alertColumns={alertColumns}
        tableTitle="Market Alerts"
        onAlertSubmit={(alertData) => {
          api.post('/market_alert', alertData)
            .then((response) => {
              setAlerts((prevAlerts) => [...prevAlerts, response.data]);
              message.success('Alert created successfully');
              setIsCreating(false);
            })
            .catch((error) => {
              message.error('Failed to create alert');
            });
        }}
        onAlertEdit={handleUpdateAlert}  
        onAlertDelete={handleDeleteAlert}  // Pass delete handler
      />
    </Modal>
  );
};

export default MarketAlerts;

