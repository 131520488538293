
import React, { useState, useEffect } from "react";
import { Button, Input, Select, Form, Divider, message, Spin, Alert, Collapse, Table, Popconfirm, Menu, Dropdown, Modal } from "antd";
import FieldSearch from "../functions/FieldSearch";
// import { fetchClauses, addClause, deleteClause, fetchTargetFieldValues } from "../functions/alertClauseService"; // Import fetchClauses, addClause, and deleteClause
import { fetchClauses, addClause, deleteClause, fetchTargetFieldValues, testClause } from "../functions/alertClauseService";
import useFetchDashboardStructure from "../functions/FetchDashboardStructure";
import TextCrafter from "./TextCrafter"; // Import the new TextCrafter component


const { Option } = Select;
const { Panel } = Collapse;

const ClauseCrafter = ({ onClausesAvailable, onClauseSubmit, onClauseRemove, isCreating, isEditing, alertClauses, setAlertClauses }) => {
    
const [dashboardStructure, setDashboardStructure] = useState(null);
const { dashboardStructure: fetchedStructure, loading: dashboardLoading, error: dashboardError } = useFetchDashboardStructure();  // Call the hook here
  
  // Only set the dashboard structure once it's fetched, and if it's not already populated
  useEffect(() => {
    if (!dashboardStructure && fetchedStructure) {
      setDashboardStructure(fetchedStructure);
    }
  }, [fetchedStructure, dashboardStructure]);
//   console.log("dashboardStructure", dashboardStructure)
    
const [form] = Form.useForm();
const [selectedClauseId, setSelectedClauseId] = useState(null);
const [isNewClause, setIsNewClause] = useState(true);
const [clauseSourceTable, setClauseSourceTable] = useState("");
const [thresholdSourceTable, setThresholdSourceTable] = useState("");
const [clauses, setClauses] = useState([]);
const [clausesLoading, setClausesLoading] = useState(true);
const [clausesError, setClausesError] = useState(null);
const [isAddingClause, setIsAddingClause] = useState(null); // Track if the user wants to add a clause (Y/N)
const [isSelectingExistingClause, setIsSelectingExistingClause] = useState(null); // Track whether they are selecting an existing clause or crafting a new one
const [selectedClauses, setSelectedClauses] = useState([]); // Store added or crafted clauses
const [availableFields, setAvailableFields] = useState([]); // Store available fields for the Clause Source Table
const [targetClauseField, setTargetClauseField] = useState(null); // Store the selected Target Clause Field
const [isValuesLoading, setIsValuesLoading] = useState(false);  // Track loading state for backend call
const [targetClauseFieldValue, setTargetClauseFieldValue] = useState(null); // Store the selected value from Available Values dropdown
const [thresholdField, setThresholdField] = useState(null);
const [targetFieldValues, setTargetFieldValues] = useState([]); // Initialize as empty array
const [targetThresholdFieldValue, setTargetThresholdFieldValue] = useState([]); // Initialize as empty array
const [selectedTargetThresholdFieldValue, setSelectedTargetThresholdFieldValue] = useState(null); // Separate state for selected value
const [testResult, setTestResult] = useState(null); // To store the test result
const [availableThresholdFields, setAvailableThresholdFields] = useState([]); // For Target Threshold Fields
const [selectedTargetThresholdField, setSelectedTargetThresholdField] = useState(null); // Define state
const [targetThresholdField, setTargetThresholdField] = useState(null); // Track selected target threshold field
const [contextMenuVisible, setContextMenuVisible] = useState(false);
const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
const [selectedClause, setSelectedClause] = useState(null);
const [isTextCrafterVisible, setIsTextCrafterVisible] = useState(false);

const showTextCrafterModal = () => {
    setIsTextCrafterVisible(true);
  };
  const handleTextCrafterClose = () => {
    setIsTextCrafterVisible(false);
  };
  
  
  // Function to handle the right-click event
  const handleRightClick = (e, record) => {
    console.log("richthand click noticed")
    e.preventDefault(); // Prevent the default right-click menu from appearing

    // Set the position of the custom context menu
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    setContextMenuVisible(true); // Show the context menu
    setSelectedClause(record); // Store the clicked clause
  };

  // Function to handle adding the clause to the alert
  const handleAddToAlert = () => {
    if (selectedClause) {
      setAlertClauses((prevClauses) => [...prevClauses, selectedClause]);
      setContextMenuVisible(false); // Hide the context menu
    }
  };

  useEffect(() => {
    const handleClickOutside = () => {
      setContextMenuVisible(false); // Hide the context menu if clicked outside
    };
  
    document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside); // Cleanup the listener
    };
  }, []);
  

  // Define the context menu
  const contextMenu = (
    <Menu>
      <Menu.Item key="1" onClick={handleAddToAlert}>
        Add to alert
      </Menu.Item>
    </Menu>
  );

  const handleTestClause = async () => {
    const values = form.getFieldsValue(); // Get the current form values
  
    const clauseToTest = {
        clause_field: values.clause_field,
        clause_field_sourcetable: clauseSourceTable,
        clause_operator: values.clause_operator,
        threshold: values.threshold_value || values.threshold,
        threshold_field: thresholdField,
        threshold_sourcetable: thresholdSourceTable,
        clause_txt: values.clause_txt,
        clause_field_target: targetClauseField,
        
        clause_field_target_value: targetClauseFieldValue, // single selected value
        
        threshold_field_target_value: selectedTargetThresholdFieldValue || values.threshold // Send the selected value
    };
    
  
    try {
      const result = await testClause(clauseToTest); // Use the testClause function from alertClauseService.js
      console.log("clause test result", result)
      // Compose the detailed message for the result in the required format
      const detailedMessage = `
        ${result.success ? 'True' : 'False'}: 
        ${result.clause_value} ${clauseToTest.clause_operator} ${result.threshold} 
        for ${clauseToTest.clause_field_target_value} in table ${clauseToTest.clause_field_sourcetable}.
        This clause ${result.success ? 'succeeds' : 'fails'}.
      `;
  
      // Set the test result with the detailed message
      setTestResult({
        success: result.success,
        message: detailedMessage.trim()  // Trim any extra spaces or lines
      });
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred while testing the clause.';
  
      // Set the test result with error message and details
      setTestResult({
        success: false,
        message: `Error: ${errorMessage}. Comparison: ${clauseToTest.clause_field_target_value} ${clauseToTest.clause_operator} ${clauseToTest.threshold}.`
      });
      
      console.error('Error testing clause:', errorMessage);
    }
  };
  
  


  // Add useState to track form validation
const [isFormValid, setIsFormValid] = useState(false);

// Add this function to handle form validation when fields change
const handleFormChange = () => {
    const fields = form.getFieldsValue();
    const isValid = fields.clause_field && fields.clause_operator && fields.clause_txt;
    setIsFormValid(isValid);
  };

  useEffect(() => {
    const fetchThresholdFieldValues = async () => {
      if (thresholdField && thresholdSourceTable) {
        setIsValuesLoading(true);
        try {
          const values = await fetchTargetFieldValues(thresholdSourceTable, thresholdField);
          // Ensure the result is always an array
          setTargetThresholdFieldValue(Array.isArray(values) ? values : []); // Ensure array type
        } catch (error) {
          message.error("Error fetching threshold field values");
        } finally {
          setIsValuesLoading(false);
        }
      }
    };
  
    fetchThresholdFieldValues();
  }, [thresholdField, thresholdSourceTable]);
  
  

useEffect(() => {
    const fetchFieldValues = async () => {
      if (clauseSourceTable && targetClauseField) {
        setIsValuesLoading(true);
        try {
          const values = await fetchTargetFieldValues(clauseSourceTable, targetClauseField);
          setTargetFieldValues(values); // Store the fetched values
        } catch (error) {
          message.error("Error fetching target field values");
        } finally {
          setIsValuesLoading(false);
        }
      }
    };
    fetchFieldValues();
  }, [clauseSourceTable, targetClauseField]);
  
  useEffect(() => {
    const fetchTargetThresholdFieldValues = async () => {
      if (thresholdSourceTable && targetThresholdField) {
        setIsValuesLoading(true); // Set loading state
        try {
          const values = await fetchTargetFieldValues(thresholdSourceTable, targetThresholdField); // Fetch values based on source table and target field
          setTargetThresholdFieldValue(values); // Set the fetched values
        } catch (error) {
          message.error('Error fetching target threshold field values');
        } finally {
          setIsValuesLoading(false); // Reset loading state
        }
      }
    };
    fetchTargetThresholdFieldValues();
  }, [thresholdSourceTable, targetThresholdField]); // Fetch values when source table or target field changes
  

useEffect(() => {
    if (selectedClauses.length > 0) {
    //   console.log("Selected Clauses:", selectedClauses);
      selectedClauses.forEach((clause, index) => {
        // console.log(`Clause ${index + 1}:`, {
        //   "Clause ID": clause.ID,
        //   "Clause Text": clause.clause_txt,
        //   "Clause Field": clause.clause_field,
        //   "Clause Operator": clause.clause_operator,
        //   "Threshold": clause.threshold || clause.threshold_value,
        //   "Threshold Field": clause.threshold_field,
        //   "Source Table": clause.clause_field_sourcetable
        // });
      });
    }
  }, [selectedClauses]);
  

  useEffect(() => {
    const getClauses = async () => {
      try {
        const fetchedClauses = await fetchClauses();  // Fetch the clauses
        console.log("it is here")
        // console.log("Fetched Clauses in ClauseCrafter:", fetchedClauses);

        setClauses(fetchedClauses);
        setClausesLoading(false);

        // Pass the fetched clauses to AlertCrafter via onClausesAvailable
        if (onClausesAvailable) {
          onClausesAvailable(fetchedClauses);  // Make sure this is called
        //   console.log("Passed Clauses to AlertCrafter:", fetchedClauses);  // Debug log
        }
      } catch (error) {
        setClausesError("Error fetching clauses");
        setClausesLoading(false);
      }
    };

    getClauses();
  }, []);
  // }, [onClausesAvailable]);

  const handleClauseFieldSelect = ({ field, table }) => {
    form.setFieldsValue({ clause_field: field, clause_field_sourcetable: table });
    setClauseSourceTable(table);
  
    // Retrieve the fields for the selected Clause Source Table from dashboardStructure
    const fields = dashboardStructure[table];
  
    // Filter out 'ID', 'epoch', and the selected Clause Field
    const filteredFields = fields
      .filter(f => f.name !== 'ID' && f.name !== 'epoch' && f.name !== field)
      .map(f => f.name);
  
    setAvailableFields(filteredFields); // Set the available fields for the Target Clause Field
  };
  

  const handleThresholdFieldSelect = ({ field, table }) => {
    form.setFieldsValue({ threshold_field: field, threshold_sourcetable: table });
    setThresholdSourceTable(table);
    setThresholdField(field);
  
    // Retrieve fields for the selected Threshold Source Table
    const fields = dashboardStructure[table];
  
    // Filter out 'ID', 'epoch', and the selected Threshold Field
    const filteredFields = fields
      .filter(f => f.name !== 'ID' && f.name !== 'epoch' && f.name !== field)
      .map(f => f.name);
  
    setAvailableThresholdFields(filteredFields); // Set available fields for Target Threshold Field
  };
  

  const handleFinish = async (values) => {
    // console.log('Form submitted', values);
  
    const craftedClause = {
        clause_field: values.clause_field,
        clause_field_sourcetable: clauseSourceTable,
        clause_operator: values.clause_operator,
        threshold: values.threshold_value || values.threshold,
        threshold_field: thresholdField,
        threshold_sourcetable: thresholdSourceTable,
        clause_txt: values.clause_txt,
        clause_field_target: targetClauseField,
        clause_field_target_value: targetClauseFieldValue, // single selected value
        threshold_field_target_value: selectedTargetThresholdFieldValue  // Use the selected value here
        // threshold_field_target_value: targetThresholdFieldValue  // single selected value
    };
  
    try {
      const createdClause = await addClause(craftedClause);
    //   console.log("Created Clause:", createdClause);
  
      // Add the created clause to the selectedClauses state, ensuring it includes the ID
      setSelectedClauses([...selectedClauses, { ...craftedClause, ID: createdClause.ID }]);
  
      form.resetFields();
      setIsNewClause(true);
      setIsAddingClause(null);
      setIsSelectingExistingClause(null);
  
      const updatedClauses = await fetchClauses();
      setClauses(updatedClauses);
    } catch (error) {
      message.error("Failed to create clause.");
    }
  };
  
  
  

const handleExistingClauseSelect = (id) => {
    const existingClause = clauses.find((clause) => clause.ID === id);

    // Update selected clauses
    setSelectedClauses([...selectedClauses, existingClause]);

    // Notify parent that a clause has been selected and should be added to the alert
    onClauseSubmit(existingClause);  // Call the parent to handle alert state

    setIsAddingClause(null);
    setIsSelectingExistingClause(null);
    message.success("Clause selected successfully!");
};


  
  
  const handleDelete = async (id) => {
    try {
      await deleteClause(id);
      message.success("Clause deleted successfully");

      const updatedClauses = await fetchClauses();
      setClauses(updatedClauses);
    } catch (error) {
      message.error("Failed to delete clause.");
    }
  };

  // Define columns for displaying clauses, including "Alerts" and "Delete" button
  const clauseColumns = [
    { title: "ID", dataIndex: "ID", key: "ID" },
    { title: "Clause Field", dataIndex: "clause_field", key: "clause_field" },
    { title: "Source Table", dataIndex: "clause_field_sourcetable", key: "clause_field_sourcetable" },
    { title: "Target Clause Field", dataIndex: "clause_field_target", key: "clause_field_target" },
    { title: "Target Clause Field Value", dataIndex: "clause_field_target_value", key: "clause_field_target_value" },
    { title: "Operator", dataIndex: "clause_operator", key: "clause_operator" },
    { title: "Threshold", dataIndex: "threshold", key: "threshold" },
    { title: "Threshold Field", dataIndex: "threshold_field", key: "threshold_field" },
    { title: "Threshold Source Table", dataIndex: "threshold_sourcetable", key: "threshold_sourcetable" },
    { title: "Target Threshold Field Value", dataIndex: "threshold_field_target_value", key: "threshold_field_target_value" },

    { title: "Clause Text", dataIndex: "clause_txt", key: "clause_txt" },
    {
      title: "Alerts",
      dataIndex: "alerts",
      key: "alerts",
      render: (alerts) => (alerts.length > 0 ? alerts.join(", ") : "None"), // Display associated alert IDs
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Button
          type="primary"
          danger
          disabled={record.alerts.length > 0} // Disable delete if alerts exist
          onClick={() => handleDelete(record.ID)}
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleCancelCrafting = () => {
    form.resetFields();  // Reset the form fields
    setIsNewClause(true); // Reset to a new clause
    setIsAddingClause(null); // Go back to the initial selection step
    setIsSelectingExistingClause(null); // Allow selection between existing or new clause
  };
  

  const handleDeselectClause = (index) => {
    const removedClause = selectedClauses[index]; // Get the removed clause
    const updatedClauses = [...selectedClauses];
    updatedClauses.splice(index, 1); // Remove the clause at the given index
    setSelectedClauses(updatedClauses); // Update local state
  
    if (onClauseRemove) {
      onClauseRemove(removedClause.ID); // Notify the parent to remove it from alertClauses
    }
  };
  
  


  return (
    <div>

        
{ (isCreating || isEditing) && (
    <div>

      {/* First selection: Add a clause? */}
      {isAddingClause === null && (
        <div>
          <h3>Add a Clause?</h3>
          <Button type="primary" onClick={() => setIsAddingClause(true)}>Yes</Button>
          <Button type="default" onClick={() => setIsAddingClause(false)}>No</Button>
        </div>
      )}

      {/* If Yes, ask to choose between existing clause or crafting a new one */}
      {isAddingClause && isSelectingExistingClause === null && (
        <div>
          <h3>Add Existing or Craft New?</h3>
          <Button type="primary" onClick={() => setIsSelectingExistingClause(true)}>Add Existing</Button>
          <Button type="default" onClick={() => setIsSelectingExistingClause(false)}>Craft New</Button>
          {/* Cancel button to go back */}
            <Button
            type="default"
            onClick={handleCancelCrafting}
            style={{ marginLeft: '8px' }}
            >
            Cancel
            </Button>
        </div>
      )}

      {/* Show list of existing clauses if "Add Existing" is selected */}
      {isSelectingExistingClause && (
  <div>
    <h3>Select Existing Clause</h3>
    <Select
      showSearch  // Enable search functionality
      placeholder="Select an existing clause"
      onChange={handleExistingClauseSelect}
      style={{ width: "100%" }}
      filterOption={(input, option) =>
        String(option.children).toLowerCase().includes(input.toLowerCase())  // Ensure we are comparing strings
      }
    >
      {clauses.map((clause) => (
        <Option key={clause.ID} value={clause.ID}>
          {`${clause.ID} - ${clause.clause_txt}`}  {/* Display both ID and clause text */}
        </Option>
      ))}
    </Select>

    {/* Cancel button to go back */}
    <Button
      type="default"
      onClick={handleCancelCrafting}
      style={{ marginLeft: '8px', marginTop: '16px' }}
    >
      Cancel
    </Button>
  </div>
)}


      {/* Show the form to craft a new clause only if "Craft New" is selected after "Yes" */}
{isAddingClause && isSelectingExistingClause === false && (
        <div>
          <h3>{isNewClause ? "Craft a New Clause" : "Edit Existing Clause"}</h3>
          <Form form={form} onFinish={handleFinish} layout="vertical" onValuesChange={handleFormChange}>

{/* Clause Field */}
<Form.Item
  label="Clause Field"
  name="clause_field"
  rules={[{ required: true, message: "Clause Field is required" }]}
>
  <FieldSearch dashboardStructure={dashboardStructure} onFieldSelect={handleClauseFieldSelect} />
</Form.Item>

{/* Clause Source Table */}
<Form.Item label="Clause Source Table" name="clause_field_sourcetable">
  <Input disabled value={clauseSourceTable} />
</Form.Item>

{/* For Target Clause Field */}
{availableFields.length > 0 && (
  <Form.Item
  label="For Target Clause Field"
  name="clause_field_target" // Update name to clause_field_target
  rules={[{ required: true, message: "Please select a Target Clause Field" }]}
>
  <Select placeholder="Select a target clause field" onChange={value => setTargetClauseField(value)}>
    {availableFields.map((field) => (
      <Option key={field} value={field}>
        {field}
      </Option>
    ))}
  </Select>
</Form.Item>

)}

{/* Conditionally Render Dropdown for Field Values */}
{targetFieldValues?.length > 0 && (
  <Form.Item label="Available Values">
    <Select
      placeholder="Select a value from target field"
      loading={isValuesLoading}
      onChange={value => setTargetClauseFieldValue(value)}  // Capture selected value
    >
      {targetFieldValues.map((value, index) => (
        <Option key={index} value={value}>
          {value}
        </Option>
      ))}
    </Select>
  </Form.Item>
)}



{/* Clause Operator */}
<Form.Item label="Clause Operator" name="clause_operator" rules={[{ required: true }]}>
  <Select>
    <Option value=">">Greater than</Option>
    <Option value="<">Less than</Option>
    <Option value="=">Equal to</Option>
    <Option value=">=">Greater than or equal to</Option>
    <Option value="<=">Less than or equal to</Option>
    <Option value="!=">Not equal to</Option>
  </Select>
</Form.Item>

{/* Threshold (Field or Value) */}
<Form.Item label="Threshold (Field or Value)" required>
  <Input.Group compact>
    <Form.Item name="threshold_field" noStyle>
      <FieldSearch dashboardStructure={dashboardStructure} onFieldSelect={handleThresholdFieldSelect} />
    </Form.Item>
    <Form.Item name="threshold_value" noStyle>
      <Input style={{ width: "50%" }} placeholder="Threshold Value" />
    </Form.Item>
  </Input.Group>
</Form.Item>

{/* Threshold Source Table */}
<Form.Item label="Threshold Source Table" name="threshold_sourcetable">
  <Input disabled value={thresholdSourceTable} />
</Form.Item>

{availableThresholdFields.length > 0 && (
  <Form.Item
    label="Target Threshold Field"
    name="target_threshold_field"
    rules={[{ required: true, message: "Please select a Target Threshold Field" }]}
  >
    <Select
      placeholder="Select a target threshold field"
      onChange={value => setTargetThresholdField(value)} // Capture selected field
    >
      {availableThresholdFields.map((field) => (
        <Option key={field} value={field}>
          {field}
        </Option>
      ))}
    </Select>
  </Form.Item>
)}


{/* Conditionally Render Dropdown for Threshold Target Values */}
{Array.isArray(targetThresholdFieldValue) && targetThresholdFieldValue.length > 0 && (
  <Form.Item label="Target Threshold Field Value">
    <Select
      placeholder="Select a value from threshold field"
      
      value={selectedTargetThresholdFieldValue}  // Ensure it binds to a single selected value
      onChange={value => setSelectedTargetThresholdFieldValue(value)}  // Set the selected value
    >
      {targetThresholdFieldValue.map((value, index) => (
        <Option key={index} value={value}>
          {value}
        </Option>
      ))}
    </Select>
  </Form.Item>
)}


{/* Clause Text */}
<Form.Item label="Clause Text" name="clause_txt" rules={[{ required: true }]}>
  <Input.TextArea rows={3} placeholder="Enter description for the clause" onClick={showTextCrafterModal} />
</Form.Item>

{/* <Form.Item label="Clause Text" name="clause_txt" rules={[{ required: true }]}>
  <Input.TextArea rows={3} placeholder="Enter description for the clause" />
</Form.Item> */}

{/* Test Clause Button */}
{isFormValid && (
  <Form.Item>
    <Button
      type="default"
      onClick={handleTestClause}
    >
      Test Clause
    </Button>
    {testResult && (
      <span>
        <b>{testResult.success ? 'True' : 'False'}</b>: {testResult.message}
      </span>
    )}
  </Form.Item>
)}



    {/* Submit Button */}
    <Form.Item>
    <Button
        type="primary"
        onClick={() => {
        form
            .validateFields()
            .then((values) => handleFinish(values))
            .catch((error) => console.log('Validation failed:', error));
        }}
        disabled={!isFormValid}
    >
        {isNewClause ? "Create Clause" : "Update Clause"}
    </Button>

{/* Cancel Button */}
<Button
          type="default"
          onClick={handleCancelCrafting}
          style={{ marginLeft: '8px' }}
        >
          Cancel
        </Button>


</Form.Item>
</Form>

        </div>
      )}

</div>
  )}


      <Divider />
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Alert Clauses" key="1">
          {clausesLoading ? (
            <Spin tip="Loading alert clauses..." />
          ) : clausesError ? (
            <Alert message={clausesError} type="error" />
          ) : (

            <Table
                columns={clauseColumns}
                dataSource={clauses}
                pagination={false}
                bordered
                style={{ fontSize: '10px', width: '80%', margin: 'auto', marginLeft: '20px' }}
                onRow={(record) => ({
                    onContextMenu: (e) => handleRightClick(e, record), // Attach the right-click event
                  })}
                />

            
          )}

                {contextMenuVisible && (
                <div
                    style={{
                    position: 'absolute',
                    left: contextMenuPosition.x,
                    top: contextMenuPosition.y + 250,  // Add a 10-pixel offset to the Y position
                    zIndex: 1000,
                    }}
                >
                    <Dropdown
                    overlay={contextMenu}
                    open={contextMenuVisible} // Use open instead of visible
                    onOpenChange={(open) => setContextMenuVisible(open)} // Use onOpenChange instead of onVisibleChange
                    trigger={['click']} // To make it clickable
                    >
                    {/* This small div ensures the dropdown gets triggered properly */}
                    <div style={{ width: '1px', height: '1px' }} />
                    </Dropdown>
                </div>
                )}

        </Panel>
      </Collapse>

      <Modal
  title="Text Crafter"
  visible={isTextCrafterVisible}
  onCancel={handleTextCrafterClose}
  footer={null}
  width={800}
>
  <TextCrafter dashboardStructure={dashboardStructure} onClose={handleTextCrafterClose} />
</Modal>


    </div>



  );
};

export default ClauseCrafter;
