//HotspotService.js
import { Modal, Button, Input, Select, Form, message } from "antd";
import { fetchTargetFieldValues} from "../functions/alertClauseService";  // Keep this import here
import React, { useState, useEffect } from "react";
import api from './api';

// Function to extract hotspots from crafted text
export const extractHotspots = (craftedText) => {
  const regex = /\{([^}]+)\}/g; // Match content inside curly braces {}
  const foundPlaceholders = [];
  let match;

  while ((match = regex.exec(craftedText)) !== null) {
    foundPlaceholders.push(match[1]); // Push placeholder name inside {}
  }

  return foundPlaceholders;
};

// Function to test the text hotspot (POST)
export const testTextHotspot = async (hotspotData) => {
    try {
      const response = await api.post('/test_text_hotspot', hotspotData, {
        headers: {
          'Content-Type': 'application/json',  // Set content type to JSON
        },
      });
      message.success('Text hotspot tested successfully!');
      return response.data;
    } catch (error) {
      message.error('Error testing text hotspot.');
      console.error('Error testing text hotspot:', error);
      throw error;
    }
  };


// Function to handle hotspot definitions
export const manageHotspotDefinitions = (existingDefinitions, hotspot, key, value) => {
  return {
    ...existingDefinitions,
    [hotspot]: {
      ...(existingDefinitions[hotspot] || {}),
      [key]: value,
    },
  };
};

// Function to fetch WHERE field values based on the selected table and field

export const fetchWhereFieldValues = async (placeholder, field, tableSelected, setWhereFieldValues) => {
    if (tableSelected && field) {
      try {
        const values = await fetchTargetFieldValues(tableSelected, field); // Fetch the field values
        console.log(`Fetching WHERE values for placeholder: ${placeholder}, table: ${tableSelected}, field: ${field}`);
        console.log("Received values from API:", values);
        
        // The array is at the top level, not inside `values.data`
        if (Array.isArray(values)) {
          setWhereFieldValues((prev) => {
            const updatedValues = { ...prev, [placeholder]: [...values] };  // Just use `values` as it's already the array
            console.log('Updated whereFieldValues state for placeholder:', updatedValues);
            return updatedValues;
          });
        } else {
          console.warn(`No valid WHERE values found for placeholder: ${placeholder}`);
        }
      } catch (error) {
        console.error(`Error fetching WHERE condition values for placeholder: ${placeholder}`, error);
      }
    } else {
      console.warn("Table or Field not selected correctly:", { tableSelected, field });
    }
};
  

// Function to generate the form items for hotspot definitions
export const generateHotspotFormItems = (
  placeholders,
  hotspotDefinitions,
  dashboardStructure,
  handleHotspotChange,
  whereFieldValues,
  setWhereFieldValues,
  onReplacePlaceholder,
  testResults,         // Receive test results state
  setTestResults,       // Receive state setter for test results
  sentData,            // Receive sent data from parent
  handleTestHotspot
//   setSentData          // Receive setter for sent data from parent
) => {
    console.log('Received handleTestHotspot:', handleTestHotspot); ; // This should log the function.
    if (typeof handleTestHotspot !== 'function') {
        throw new Error('handleTestHotspot is not a function!'); // Catch and fix the issue if it's undefined
      }

  return placeholders.map((placeholder, index) => {
    const tableSelected = hotspotDefinitions?.[placeholder]?.table;
    const fieldSelected = hotspotDefinitions?.[placeholder]?.field;
    const whereFieldSelected = hotspotDefinitions?.[placeholder]?.whereField;
    const whereFieldValueSelected = hotspotDefinitions?.[placeholder]?.whereFieldValue; // Fetch selected value for WHERE
  
 
    return (
      <div key={index} style={{ marginBottom: 16 }}>
        <h5>{`{${placeholder}}`}</h5>

        {/* Dropdown for selecting table */}
        <Form.Item label="Table">
          <Select
            showSearch
            onChange={(value) => handleHotspotChange(placeholder, "table", value)}
            placeholder="Select Table"
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dashboardStructure &&
              Object.keys(dashboardStructure).map((table) => (
                <Select.Option key={table} value={table}>
                  {table}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        {/* Dropdown for selecting field */}
        <Form.Item label="Field">
          <Select
            showSearch
            onChange={(value) => handleHotspotChange(placeholder, "field", value)}
            placeholder="Select Field"
            disabled={!tableSelected}
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {tableSelected &&
              dashboardStructure?.[tableSelected]?.map((field) => (
                <Select.Option key={field.name} value={field.name}>
                  {field.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        {/* Dropdown for WHERE Condition Field */}
        <Form.Item label="WHERE Condition Field (optional)">
                <Select
                    showSearch
                    onChange={(value) => {
                    handleHotspotChange(placeholder, "whereField", value);
                    console.log('IN SELECT: Selected whereField:', value); // Log the correct value for the selected WHERE Field
                    fetchWhereFieldValues(placeholder, value, tableSelected, setWhereFieldValues); // Correct function call
                    }}
                    placeholder="Select WHERE Field"
                    disabled={!tableSelected}
                    filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {tableSelected &&
                    dashboardStructure?.[tableSelected]?.map((field) => (
                        <Select.Option key={field.name} value={field.name}>
                        {field.name}
                        </Select.Option>
                    ))}
                </Select>
                </Form.Item>


        {/* Dropdown for WHERE Condition Value */}
        <Form.Item label="WHERE Condition Value (optional)">
          <Select
            
            key={JSON.stringify(whereFieldValues?.[placeholder] || [])}
            showSearch
            placeholder="Select WHERE Condition Value"
            value={whereFieldValueSelected || undefined}  // Ensure correct value is displayed
            onChange={(value) => {
                console.log(`WHERE Condition Value changed for placeholder: ${placeholder}, selected value: ${value}`);
                handleHotspotChange(placeholder, "whereFieldValue", value);
              }}
            
            // disabled={!hotspotDefinitions?.[placeholder]?.whereField || !whereFieldValues?.[placeholder]?.length}

            // disabled={!whereFieldSelected || !whereFieldValues?.[placeholder]?.length}

            // disabled={!whereFieldSelected || !Array.isArray(whereFieldValues?.[placeholder])}
            filterOption={(input, option) =>
              option.value.toLowerCase().includes(input.toLowerCase())
            }
          >
            {console.log("Rendering WHERE Condition Value options for", placeholder, whereFieldValues?.[placeholder])}
            
            {Array.isArray(whereFieldValues?.[placeholder]) ? (
                    whereFieldValues[placeholder].map((value) => (
                        <Select.Option key={value} value={value}>
                        {value}
                        </Select.Option>
                    ))
                    ) : (
                    <Select.Option disabled>No values available</Select.Option>  // Handle case where values are not available
                    )}

            {/* {whereFieldValues?.[placeholder]?.map((value) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))} */}
          </Select>
        </Form.Item>

        {/* Display the "Test Hotspot" button when the table, field, and WHERE Condition Value are selected */}
        {(tableSelected !== undefined && fieldSelected !== undefined && whereFieldValueSelected !== undefined) ? (
                        <div>
                            <Button
  type="primary"
  onClick={() =>
    handleTestHotspot(
      placeholder,
      hotspotDefinitions?.[placeholder]?.table,
      hotspotDefinitions?.[placeholder]?.field,
      hotspotDefinitions?.[placeholder]?.whereField,
      hotspotDefinitions?.[placeholder]?.whereFieldValue
    )
  }
>
  Test Hotspot
</Button>


                            {/* Display the result after testing */}
                            {testResults?.[placeholder] !== undefined ? (
  <div>
     {console.log("Sent Data to Backend:", sentData)}
    <p><strong>Sent to Backend:</strong> {JSON.stringify(sentData)}</p> {/* Display the data sent to the backend */}
    <p><strong>Result:</strong> {testResults[placeholder]?.result}</p> {/* Display the result */}
    <p><strong>Success:</strong> {testResults[placeholder]?.success ? 'Yes' : 'No'}</p> {/* Display success or failure */}
  </div>
) : (
  <p>No test result yet.</p>
)}



                        </div>
                        ) : (
                        <p>Ensure all fields are selected to test the hotspot</p>
                        )}


        {/* Display the "Change" button when the table and field are selected */}
        {tableSelected && fieldSelected && (
          <div>
            <p>
              Change text {`{${placeholder}}`} into {"<"}
              {tableSelected}, {fieldSelected}
              {whereFieldSelected && `, ${whereFieldSelected}`}
              {whereFieldValueSelected && `, ${whereFieldValueSelected}`}
              {">"}
            </p>

            <Button
              type="primary"
              onClick={() =>
                onReplacePlaceholder(
                  placeholder,
                  `<${tableSelected}, ${fieldSelected}${
                    whereFieldSelected ? `, ${whereFieldSelected}` : ""
                  }${whereFieldValueSelected ? `, ${whereFieldValueSelected}` : ""}>`
                )
              }
            >
              Change
            </Button>
          </div>
        )}
      </div>
    );
  });
};
